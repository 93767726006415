import { PublicPageLayout } from 'components/layouts/public-page';
import {
  ContentArea,
  ContentAreaHeader,
} from 'design-system/components/content-area';
export default function Custom404() {
  return (
    <PublicPageLayout>
      <ContentArea style={{ margin: '2em 10%' }}>
        <ContentAreaHeader>404 - Page Not Found</ContentAreaHeader>
      </ContentArea>
    </PublicPageLayout>
  );
}
